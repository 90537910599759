



import { defineComponent } from '@nuxtjs/composition-api';
import BenNav from '~/components/BenNav/Nav.vue';

export default defineComponent({
  components: {
    BenNav,
  },
});
